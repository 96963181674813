<template>
  <div v-if="personalized" class="layout-padding">
    <div class="flex flex-wrap flex-col justify-between mb-6 mt-12">
      <div
        class="personalized-candles-title-bg flex p-12 flex-col justify-center text-white relative"
        :style="`background: url(${personalized.image})`"
      >
        <div class="personalized-candles-title-overlay"></div>
        <div
          class="page-title z-1 lora-bold mb-6 me-6"
          v-html="content.personalizedCandles"
        ></div>

        <div class="breadcrumbs z-1 text-size-12 mb-6">
          <span class="cursor-pointer" @click="$router.push('/')">
            {{ content.home }}
          </span>
          <span class="cursor-pointer" @click="$router.push('/shop')">
            / {{ content.shop }}
          </span>
          <span class="cursor-pointer">
            / {{ content.personalizedCandles }}
          </span>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-2 gap-x-0 lg:gap-x-20 my-12">
      <div class="col-span-2 lg:col-span-1 personalized-candles-content">
        <div class="text-lg lora-bold">
          {{ personalized.title }}
        </div>

        <div class="my-8 personalized-candles-description">
          {{ personalized.description }}
        </div>
      </div>
      <div class="col-span-2 lg:col-span-1">
        <div class="text-lg lora-bold text-secondaryColor">
          {{ content.requestPersonalizedCandles }}
        </div>
        <div class="col-span-2 md:col-span-1">
          <div class="flex flex-col sm:flex-row mt-8">
            <label for="notes" class="input-label mb-2 sm:mb-0 flex">
              {{ content.notes }}
            </label>
            <textarea
              type="text"
              style="resize: none"
              class="form-input h-40"
              v-model="notes"
              id="notes"
              :placeholder="content.name"
            />
          </div>
        </div>
        <div class="col-span-2 md:col-span-1 relative">
          <div class="flex flex-col sm:flex-row mt-8">
            <label
              for="file"
              class="input-label mb-2 sm:mb-0 flex items-center"
            >
              {{ content.addFiles }}
            </label>
            <label for="file" class="w-full upload-input upload-input-p-0">
              <input
                type="file"
                id="file"
                style="display: none"
                @change="uploadFile"
              />
              <div class="flex justify-between w-full border-with-radius">
                <div class="flex items-center ms-4">
                  {{ content.uploadFile }}
                </div>
                <div class="upload-select lora-bold">{{ content.select }}</div>
              </div>
            </label>
          </div>
          <p class="error mt-2 h-5">
            {{ error }}
          </p>
        </div>
        <div class="flex justify-end my-8">
          <button
            v-if="buttonDisabled"
            class="alert-btn bg-cancelled bg-opacity-10 text-cancelled border-cancelled border-1 lora-bold whitespace-nowrap duration-300 cursor-default"
          >
            {{ content.submitRequest }}
          </button>
          <button
            v-else
            class="alert-btn text-text-white text-white lora-bold w-max-content whitespace-nowrap bg-bluePrimary duration-300 hover:text-bluePrimary hover:bg-blueSecondary"
            @click="recaptcha"
          >
            {{ content.submitRequest }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import addPhoto from "../../imageUpload";

export default {
  inject: ["content"],
  data() {
    return {
      notes: "",
      file: null,
      error: null,
      token: null,
      buttonDisabled: false,
    };
  },
  computed: {
    personalized() {
      return this.$store.getters["pages/text"]("personalized");
    },
    user() {
      return this.$store.getters["user/user"];
    },
  },
  methods: {
    async uploadFile(event) {
      this.error = null;

      if (!this.user) {
        this.$store.dispatch("feedback/setFeedback", {
          type: "fail",
          title: this.content.fail,
          message: this.content.needToSignIn,
        });
        return;
      }
      let file = event.target.files[0];
      if (file.size / 1024 > 700) {
        this.error = this.content.largeFile;
        return;
      }
      let type1 = file["type"].split("/")[0];
      let type2 = file["type"].split("/")[1];

      if (
        type1 == "image" ||
        type2 == "pdf" ||
        type2 == "vnd.openxmlformats-officedocument.wordprocessingml.document"
      ) {
        this.file = file;
      } else {
        this.error = this.content.fileType;
      }
      // let file = event.target.files[0];
      // let reader = new FileReader();
      // reader.readAsDataURL(file);
      // reader.onload = async (e) => {
      //   this.file = e.target.result;
      //   // this.$emit("done", image);
      // };
    },
    async recaptcha() {
      let grecaptcha = window.grecaptcha;

      grecaptcha.ready(() => {
        grecaptcha
          .execute("6LeeHJEeAAAAAHNJtMdpCWjDX6bD6rJTzJfmDkTc", {
            action: "submit",
          })
          .then((token) => {
            this.token = token;
            this.submitRequest();
          });
      });
    },
    async submitRequest() {
      if (!this.user) {
        this.$store.dispatch("feedback/setFeedback", {
          type: "fail",
          title: this.content.fail,
          message: this.content.needToSignIn,
        });
        return;
      }

      if (this.notes == "") return;

      this.buttonDisabled = true;

      let input = {
        notes: this.notes,
        token: this.token,
      };

      let link = null;
      if (this.file) link = await addPhoto(this.file);
      if (link) input.file = link;

      try {
        await this.$store.dispatch("personalized/makeRequest", input);
        await this.$store.dispatch("feedback/setFeedback", {
          type: "success",
          title: this.content.success,
          message: this.content.requestSuccess,
        });
        this.buttonDisabled = false;
        this.$router.replace("/");
      } catch (error) {
        this.buttonDisabled = false;
        if (error[0].message == "bad.request") {
          this.$store.dispatch("feedback/setFeedback", {
            type: "fail",
            title: this.content.badRequest,
            message: this.content.tryAgainLater,
          });
        } else {
          this.$store.dispatch("feedback/setFeedback", {
            type: "fail",
            title: this.content.fail,
            message: this.content.personalizedFail,
          });
        }
      }
      this.buttonDisabled = false;
    },
  },
};
</script>
